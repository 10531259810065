<template>
  <div class="warp" :style="backgroundImage">
    <!-- <dv-border-box-11 title="安军客户看板">dv-border-box-11</dv-border-box-11> -->
    <!-- 标题 -->
    <title-cmp />

    <!-- 数据部分 -->
    <main-cmp />
  </div>
</template>

<script>
import TitleCmp from './cmp/TitleCmp.vue';
import MainCmp from './cmp/MainCmp.vue';
// import { dvFullScreenContainer } from '@jiaminghi/data-view';
const bg = require('@/assets/image/customer/bg.jpg');

export default {
  components: {
    'title-cmp': TitleCmp,
    'main-cmp': MainCmp
  },
  created() {
    this.backgroundImage = {
      backgroundImage: `url(${bg})`
    };
  }
};
</script>

<style scoped>
.warp {
  /* height: 100%; */
  /* height: 90vh; */
  min-height: 1000px;
  height: 100vh;
  /* maxheight: 1000px; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  /* border: 1px solid red; */

}
</style>
