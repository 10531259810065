import request from '@/utils/request';

// 国家货量
export const apiCountryOrders = () => {
  return request.post('/statistics/order/countryOrders');
};

// 渠道货量占比
export const apiChannelOrders = () => {
  return request.post('/statistics/order/channelOrders');
};

// 货量（客户）排行
export const apiCustomerOrders = () => {
  return request.post('/statistics/order/customerOrders');
};

// 客户金额贡献排行
export const apiCustomerContributions = () => {
  return request.post('/statistics/order/customerContributions');
};

// 近30天订单统计
export const apiThirtyDaysOrders = () => {
  return request.post('/statistics/order/thirtyDaysOrders');
};

// 客户订单总数
export const apiTotalOrders = () => {
  return request.post('/statistics/order/total/orders');
};

// 订单总收入
export const apiChannelRevenue = () => {
  return request.post('/statistics/order/channelRevenue');
};

