<template>
  <div class="box">
    <div class="box_left" :style="backgroundImageL">1</div>
    <div class="box_right" :style="backgroundImageR">
      <div class="times">
        <p>{{ hourStr }}</p>
        <p>:</p>
        <p>{{ minuteStr }}</p>
        <p>:</p>
        <p>{{ secondsStr }}</p>
      </div>
    </div>
    <div class="title" :style="backgroundImageHva">
      <p>安骏客户看板</p>
    </div>
  </div>
</template>

<script>
const bgLeft = require('@/assets/image/customer/l.png');
const bgRight = require('@/assets/image/customer/r.png');
const hva = require('@/assets/image/customer/hva.png');

import { setDateTime3 } from '@/utils/date_util';
export default {
  data() {
    return {
      hourStr: '',
      minuteStr: '',
      secondsStr: '',
      times: 0
    };
  },

  created() {
    this.backgroundImageL = {
      backgroundImage: `url(${bgLeft})`
    };

    this.backgroundImageR = {
      backgroundImage: `url(${bgRight})`
    };

    this.backgroundImageHva = {
      backgroundImage: `url(${hva})`
    };

    this.getTime();
  },

  methods: {
    getTime() {
      const timeStr = setDateTime3(new Date()).split(' ')[1];
      const itmeArr = timeStr.split(':');
      if (this.secondsStr !== itmeArr[2]) {
        this.hourStr = itmeArr[0];
        this.minuteStr = itmeArr[1];
        this.secondsStr = itmeArr[2];
      }

      clearTimeout(this.times);
      this.times = setTimeout(() => {
        this.getTime();
      }, 500);
    }
  }
};
</script>

<style scoped>
p {
  margin: 0;
}
.box {
  position: relative;
  top: 0;
  left: 0;
  /* border: 1px solid red; */
  height: 75px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
}

.box_left,
.box_right {
  width: 49.5%;
  height: 75px;
  /* border: 1px solid yellow; */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 102% 102%;
  position: relative;
  top: 0;
  left: 0;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 550px;
  padding: 0 0 0 22px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
}

.title p {
  color: #fff;
  font-size: 35px;
  line-height: 54px;
  letter-spacing: 22px;
  text-shadow: rgb(122 96 255) 0px 0px 19px;
}

.times {
  position: absolute;
  right: 100px;
  bottom: 14px;
  display: flex;
  align-items: center;
}
.times p {
  margin: 0 10px 0 0;
  /* border: 1px solid red; */
  font-size: 28px;
  color: #d3cdff;
  letter-spacing:5px
}
</style>
