<template>
  <div class="warpp">
    <div class="war_cont">
      <div class="cont_left">
        <box-cmp title="渠道货量占比">
          <channel-cmp :channel-arr="channelArr" />
        </box-cmp>
        <box-cmp title="货量排行">
          <cargo-vlume-cmp :customer-arr="customerArr" />
        </box-cmp>
      </div>
      <div class="cont_con">
        <middle-cmp
          :total-revenue="totalRevenue"
          :total-quantity="totalQuantity"
        />
      </div>
      <div class="cont_right">
        <box-cmp title="国家货量排行">
          <country-cmp :country-arr="countryArr" />
        </box-cmp>
        <box-cmp title="金额贡献排行">
          <amount-of-money-cmp :customer-contributions-arr="customerContributionsArr" />
        </box-cmp>
      </div>
    </div>
    <div>
      <line-cmp
        :date-list="lately30DayObj.dateList"
        :count-list="lately30DayObj.countList"
      />
    </div>
  </div>
</template>

<script>
import BoxCmp from './BoxCmp.vue';
import ChannelCmp from './ChannelCmp.vue';
import CargoVolumeCmp from './CargoVolumeCmp.vue';
import CountryCmp from './CountryCmp.vue';
import AmountOfMoneyCmp from './AmountOfMoneyCmp.vue';
import MiddleCmp from './MiddleCmp.vue';
import LineCmp from './LineCmp.vue';

import {
  apiCountryOrders,
  apiChannelOrders,
  apiCustomerOrders,
  apiCustomerContributions,
  apiThirtyDaysOrders,
  apiTotalOrders,
  apiChannelRevenue
} from '@/api/bulletinBoard.js';

const STORAGE_KEY = {
  'BulletinBoardCountry': {
    code: 'BulletinBoardCountry',
    text: '国家货量'
  },
  'BulletinBoardChannel': {
    code: 'BulletinBoardChannel',
    text: '渠道货量'
  },
  'BulletinBoardCustomer': {
    code: 'BulletinBoardCustomer',
    text: '客户货量排行'
  },
  'BulletinBoardCustomerContributions': {
    code: 'BulletinBoardCustomerContributions',
    text: '客户金额贡献'
  },
  'BulletinBoardLately30Day': {
    code: 'BulletinBoardLately30Day',
    text: '过去30天'
  },
  'BulletinBoardChannelRevenue': {
    code: 'BulletinBoardChannelRevenue',
    text: '订单总收入'
  },
  'BulletinBoardTotalOrders': {
    code: 'BulletinBoardTotalOrders',
    text: '客户订单总数'
  }
};

export default {
  components: {
    'box-cmp': BoxCmp,
    'channel-cmp': ChannelCmp,
    'cargo-vlume-cmp': CargoVolumeCmp,
    'country-cmp': CountryCmp,
    'amount-of-money-cmp': AmountOfMoneyCmp,
    'middle-cmp': MiddleCmp,
    'line-cmp': LineCmp
  },

  data() {
    return {
      countryArr: [], // 国家货量排行
      channelArr: [], // 渠道货量
      customerArr: [], // 客户货量排行
      customerContributionsArr: [], // 客户金额贡献
      lately30DayObj: { // 过去30天
        dateList: [],
        countList: []
      },

      totalRevenue: 0, // 总收入
      totalQuantity: 0 // 总数

    };
  },

  created() {
    this.asyncThirtyDaysOrders();

    setTimeout(() => {
      this.asyncCountryOrders();
      this.asyncChannelOrders();
      this.asynCustomerOrders();
      this.asyncCustomerContributions();

      this.asyncTotalOrders();
      this.asyncaChannelRevenue();
    }, 300);
  },

  methods: {

    // 数据格式整理
    seDataType(list) {
      const arr = list.sort(function(a, b) {
        return b.value - a.value;
      });
      console.log(arr);
      if (arr.length <= 5) {
        return arr;
      }
      const map = new Map();
      arr.forEach(item => {
        if (map.size <= 3) {
          map.set(item.name, item.value);
          return;
        }
        if (map.has('其他')) {
          // 存在
          const value = map.get('其他') + item.value;
          map.set('其他', value);
        } else {
          map.set('其他', item.value);
        }
      });
      const newArr = [];
      for (const [key, value] of map) {
        const obj = {
          name: key,
          value
        };
        newArr.push(obj);
      }
      // console.log(newArr);
      return newArr;
    },

    // 获取国家货量
    asyncCountryOrders() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardCountry'].code);
      if (arr.length !== 0) {
        console.log('获取国家货量 存在');
        this.countryArr = arr;
        return;
      }

      apiCountryOrders().then(res => {
        if (res.code !== 200) { return; }
        const arr = res.data.map(item => {
          return {
            name: item.countryName,
            value: item.count
          };
        });
        const list = this.seDataType(arr);
        this.countryArr = list;
        const obj = {
          arr: list,
          date: this.getPastTimesDate()
        };
        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardCountry'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 渠道货量占比
    asyncChannelOrders() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardChannel'].code);
      if (arr.length !== 0) {
        console.log('获取 渠道货量占比 存在');
        this.channelArr = arr;
        return;
      }

      apiChannelOrders().then(res => {
        if (res.code !== 200) { return; }
        console.log(res);
        const arr = res.data.map(item => {
          return {
            name: item.channelName,
            value: item.count
          };
        });
        const list = this.seDataType(arr);
        this.channelArr = list;

        const obj = {
          arr: list,
          date: this.getPastTimesDate()
        };

        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardChannel'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 货量排行
    asynCustomerOrders() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardCustomer'].code);
      if (arr.length !== 0) {
        console.log('获取 货量排行 存在');
        this.customerArr = arr;
        return;
      }

      apiCustomerOrders().then(res => {
        if (res.code !== 200) { return; }
        console.log(res);
        const arr = res.data.filter(item => {
          return item.customerName !== undefined;
        }).map(item => {
          return {
            name: item.customerName,
            value: item.count
          };
        });
        const list = this.seDataType(arr);
        this.customerArr = list;

        const obj = {
          arr: list,
          date: this.getPastTimesDate()
        };

        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardCustomer'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 客户金额贡献排行
    asyncCustomerContributions() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardCustomerContributions'].code);
      if (arr.length !== 0) {
        console.log('获取 客户金额贡献排行 存在');
        this.customerContributionsArr = arr;
        return;
      }

      apiCustomerContributions().then(res => {
        if (res.code !== 200) { return; }
        console.log(res);
        const arr = res.data.map(item => {
          return {
            name: item.orderDate,
            value: item.profits
          };
        });

        const list = this.seDataType(arr);
        this.customerContributionsArr = list;

        const obj = {
          arr: list,
          date: this.getPastTimesDate()
        };

        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardCustomerContributions'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 近30天订单统计
    asyncThirtyDaysOrders() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardLately30Day'].code);
      console.log(arr);
      if (!Array.isArray(arr)) { // 如果存在，则arr 不是数组，是对象
        console.log('获取 近30天订单统计 存在');
        const { dateList, countList } = arr;
        this.lately30DayObj.dateList = dateList;
        this.lately30DayObj.countList = countList;
        return;
      }

      apiThirtyDaysOrders().then(res => {
        console.log(res);
        if (res.code !== 200) { return; }
        console.log(res);
        const arr = this.get30DayDate();
        const { dateList, countList } = this.get30Data(arr, res.data);
        this.lately30DayObj.dateList = dateList;
        this.lately30DayObj.countList = countList;

        const obj = {
          arr: this.lately30DayObj,
          date: this.getPastTimesDate()
        };

        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardLately30Day'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 客户订单总数
    asyncTotalOrders() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardTotalOrders'].code);
      if (arr.length !== 0) {
        console.log('获取 客户订单总数 存在');
        this.totalQuantity = arr[0];
        return;
      }

      apiTotalOrders().then(res => {
        if (res.code !== 200) { return; }
        console.log(res);
        console.log('客户订单总数');
        const totalQuantity = res.data[0].count;

        this.totalQuantity = totalQuantity;

        const obj = {
          arr: [totalQuantity],
          date: this.getPastTimesDate()
        };

        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardTotalOrders'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 订单总收入
    asyncaChannelRevenue() {
      // 临时存储
      const arr = this.getStorageBool(STORAGE_KEY['BulletinBoardChannelRevenue'].code);
      if (arr.length !== 0) {
        console.log('获取 订单总收入 存在');
        this.totalRevenue = arr[0];
        return;
      }

      apiChannelRevenue().then(res => {
        if (res.code !== 200) { return; }
        // console.log(res);
        console.log('订单总收入');

        const val = res.data.reduce((acc, item) => {
          return acc + item.profits;
        }, 0);

        const totalRevenue = Number((val / 10000).toFixed(2))

        console.log(totalRevenue);

        this.totalRevenue = totalRevenue;

        const obj = {
          arr: [totalRevenue],
          date: this.getPastTimesDate()
        };

        window.sessionStorage.setItem(STORAGE_KEY['BulletinBoardChannelRevenue'].code, JSON.stringify(obj));
      }).catch(err => {
        console.error(err);
      });
    },

    // 获取最近30天的日期
    get30DayDate() {
      const arr = [];
      for (let i = 1; i <= 30; i++) {
        const str = this.getPastTimesDate(i);
        arr.push(str);
      }
      return arr.reverse();
    },

    getPastTimesDate(val = 0) {
      const date = new Date();
      date.setDate(date.getDate() - val); // 获取 val 天后的日期
      const y = date.getFullYear();
      const m = (date.getMonth() + 1 + '').padStart(2, '0');// 获取当前月份的日期
      const d = (date.getDate() + '').padStart(2, '0');
      return y + '-' + m + '-' + d;
    },

    // 最近30天 数据整理
    get30Data(dateArr, arr) {
      const map = new Map();
      dateArr.forEach(item => {
        map.set(item, 0);
      });

      arr.forEach(item => {
        if (map.has(item.orderDate)) {
          map.set(item.orderDate, item.count);
        }
      });

      const list = [];
      const dataList = [];
      for (const [date, val] of map) {
        list.push(val);
        const arr = date.split('-');
        dataList.push(arr[1] + '-' + arr[2]);
      }
      return {
        dateList: dataList,
        countList: list
      };
    },

    // 判断临时存储是否存在
    /**
     * 判断临时存储是否存在 同时判断日期
     * key 浏览器存储的 键
     * date 时间戳
     */
    getStorageBool(key) {
      const jsonStr = window.sessionStorage.getItem(key);
      if (!jsonStr) {
        // 不存在
        return [];
      }
      console.log(jsonStr);
      const { date, arr } = JSON.parse(jsonStr);
      const newDate = this.getPastTimesDate(0);
      if (date === newDate) {
        return arr;
      }
      return [];
    }

  }
};
</script>

<style scoped>
.warpp {
  height: calc(100vh - 75px - 84px);
}

.war_cont {
  display: flex;
  justify-content: space-between;
}

.cont_left,
.cont_right {
  width: 490px;
  flex-grow: 0;
}

.cont_con {
  flex-grow: 1;
  height: 55vh;
}
</style>
