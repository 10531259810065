<template>
  <div>
    <!-- <dv-conical-column-chart :config="config" style="width:450px;height:250px;" /> -->
    <dv-charts :option="option" style="width:450px;height:250px;" />
  </div>
</template>

<script>
import { setStringEnter } from '@/utils/str_util';
export default {
  props: {
    customerContributionsArr: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      option: {
        xAxis: {
          name: '客户',
          data: ['周一sfsf士\n大夫是否', '周二', '周三', '周四', '周五'],
          axisLine: { // x 轴线
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: { // x轴 字体
            style: {
              fill: '#fff'
            }
          },
          axisTick: {
            show: false
          },
          nameTextStyle: {
            fill: '#fff',
            fontSize: 14
          }
        },
        yAxis: {
          name: '金额(万元)',
          data: 'value',
          axisTick: {
            show: false
          },
          min: 0,
          splitLine: {
            // 横向刻度线
            show: false
          },
          axisLine: { // y 轴线
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: { // y轴 字体
            style: {
              fill: '#fff',
              width: '100px'
            }
          },
          nameTextStyle: {
            fill: '#fff',
            fontSize: 14

          }
        },
        series: [
          {
            data: [1200, 2230, 1900, 2100, 3500],
            type: 'bar',
            gradient: {
              color: ['rgba(24,144,255, .8)', 'rgba(24,144,255, .2)']
            }
          }
        ]
      }
    };
  },

  watch: {
    customerContributionsArr: {
      handler(val) {
        if (val.length !== 0) {
          const nameArr = [];
          const valueArr = [];

          val.forEach(item => {
            const str = setStringEnter(item.name, 5);
            //
            const val = Number((item.value / 10000).toFixed(2));
            nameArr.push(str);
            valueArr.push(val);
          });

          const obj = JSON.parse(JSON.stringify(this.option));
          obj.series[0].data = valueArr;
          obj.xAxis.data = nameArr;
          this.option = obj;
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    // 字符串超过一定长度进行换行
  }
};
</script>

<style>

</style>
