<template>
  <div class="box">
    <div class="box_head">
      <div class="head_des">
        <p>订单总收入</p>
        <div>
          <p>{{ totalRevenue }}</p>
          <p>/万元</p>
        </div>
      </div>
      <div class="head_des">
        <p>客户订单总数</p>
        <div>
          <p>{{ totalQuantity }}</p>
          <p>/包裹</p>
        </div>
      </div>
    </div>
    <div class="ornaments">
      <!-- 边框 -->
      <video
        ref="circleRef"
        src="https://anjunggl.oss-cn-shenzhen.aliyuncs.com/upload-file/workOrder/backgroundCircle-20211227T152445.mp4"
        autoplay
        loop
        muted
        class="circle"
      />
      <!-- 头像 -->

      <video
        ref="headOutSrc"
        src="https://anjunggl.oss-cn-shenzhen.aliyuncs.com/upload-file/workOrder/headOut-20211227T152622.mp4"
        class="headout"
      />

      <!-- 提示 -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 总收入
    totalRevenue: {
      type: Number,
      default: 0
    },

    // 总数
    totalQuantity: {
      type: Number,
      default: 0
    }
  },

  created() {
    setTimeout(() => {
      // this.$refs
      this.playCircle(this.$refs.circleRef);
    }, 1000);
  },

  methods: {
    // 播放边框
    playCircle(ref) {
      // console.log(ref);
      // // ref.on('load', () => {
      // const videoPlay = ref.play();
      // videoPlay.then(() => {
      //   console.log(' 可以自动');
      // }).catch(err => {
      //   console.log('不允许自动播放');
      //   ref.play();
      // });
      // // });
      ref.addEventListener('load', () => {
        ref.play();
      });
    }
  }
};
</script>

<style scoped>
p {
  margin: 0;
}

.box_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.head_des p:first-of-type{
  font-size: 25px;
  color: rgba(230, 247, 255, 0.8);
  letter-spacing: 2px;
}

.head_des div {
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
}

.head_des div p:first-of-type {
  text-shadow: rgb(19 128 255) 0px 0px 10px;
  height: 40px;
  line-height: 40px;
  font-size: 40px;
  color: #fff;
}

.head_des div p:last-of-type {
  color: rgb(15, 146, 255);
  font-size: 18px;
  display: inline-block;
}


.ornaments {
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 700px;
  height: 400px;
}

.circle,
.headout {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
}

.circle {
  height: 650px;
}

.headout {
  height: 428px;
  z-index: 10;
}

</style>
