<template>
  <div class="box">
    <div class="head">
      {{ title }}
      <div class="head_left" :style="backgroundImageL" />
      <div class="head_right" :style="backgroundImageR" />
    </div>
    <div class="main">
      <slot />
    </div>
  </div>
</template>

<script>
const bgL = require('@/assets/image/customer/head_left.png');
const bgR = require('@/assets/image/customer/head_right.png');
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },

  created() {
    this.backgroundImageL = {
      backgroundImage: `url(${bgL})`
    };

    this.backgroundImageR = {
      backgroundImage: `url(${bgR})`
    };
  }
};
</script>

<style scoped>
.box {
  padding: 0 10px;
  /* border: 1px solid rgb(0, 255, 98); */
}

.head {
  position: relative;
  top: 0;
  left: 0;
  width: 470px;
  height: 54px;
  line-height: 54px;
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.head_left,
.head_right {
  position: absolute;
  /* top: 50%; */
  top: 0;
  width: 460px;
  height: 51px;
  background-repeat: no-repeat;
  background-size: 60% 400%;
}
.head_left {
  left: 0;
  background-position: 0% 50%;
}

.head_right {
  right: 0;
   background-position: 100% 50%;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
