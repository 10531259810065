/**
 * 字符串换行
 * @param {*} str 字符串
 * @param {*} len 需要换行的长度
 * @returns
 */
 const setStringEnter = function setStringEnter(str = '', len = 4) {
  if (str.length <= len) {
    return str;
  }

  let newStr = '';
  str.split('').forEach((item, index) => {
    if ((index % len) !== 0) {
      newStr += item;
      return;
    } else {
      newStr = newStr + '\n' + item;
    }
  });
  return newStr;
};
export {
  setStringEnter
}
