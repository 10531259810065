<template>
  <div class="warp">
    <div class="title">
      <div class="title_box">
        <div class="title_left" :style="backgroundImageL" />
        <div class="title_cen" :style="backgroundImageC" />
        <div class="title_right" :style="backgroundImageR" />
      </div>
      <p>近30天发货趋势</p>
    </div>
    <dv-charts :option="option" class="line" />
  </div>
</template>

<script>
const bgL = require('@/assets/image/customer/tl.png');
const bgC = require('@/assets/image/customer/cl.png');
const bgR = require('@/assets/image/customer/rl.png');
export default {
  props: {
    dateList: {
      type: Array,
      default() {
        return [];
      }
    },

    countList: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      option: {
        xAxis: {
          name: '最近30天',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          axisLine: { // x 轴线
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: { // x轴 字体
            style: {
              fill: '#fff'
            }
          },
          axisTick: {
            show: false
          },
          nameTextStyle: {
            fill: '#fff',
            fontSize: 10
          }

        },
        yAxis: {
          name: '件数',
          data: 'value',
          axisTick: {
            show: false
          },
          min: 0,
          splitLine: {
            // 横向刻度线
            show: false
          },
          axisLine: { // y 轴线
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: { // y轴 字体
            style: {
              fill: '#fff'
            }
          },
          nameTextStyle: {
            fill: '#fff',
            fontSize: 10
          }
        },
        series: [
          {
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            // label: {
            //   fontSize: 20
            // },
            lineStyle: {
              stroke: '#117edc'
            },
            label: {
              show: true,
              formatter: '{value} 件'
            }
          }
        ],
        color: ['#fff']
      }
    };
  },

  watch: {
    countList: {
      handler(val) {
        if (val.length === 0) { return; }
        const obj = JSON.parse(JSON.stringify(this.option));

        obj.xAxis.data = this.dateList;
        obj.series[0].data = val;
        this.option = obj;
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.backgroundImageL = {
      backgroundImage: `url(${bgL})`
    };
    this.backgroundImageC = {
      backgroundImage: `url(${bgC})`
    };
    this.backgroundImageR = {
      backgroundImage: `url(${bgR})`
    };
  }
};
</script>

<style scoped>
.warp {
  height: 170px;
  color: rgb(255, 255, 255);
  position: relative;
  top: 0;
  left: 0;
  /* border: 1px solid red; */
}
.title {
  position: absolute;
  top: -40px;
  left: 0;
  width: 450px;
  height: 50px;
}

.title p {
  margin: 0;
  position: absolute;
  top: 13px;
  left: 45px;
  text-shadow: rgb(95 96 255) 0px 0px 10px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  letter-spacing: 0px;
  /* line-height: 48px; */
  font-weight: normal;
}

.title_box {
  width: 450px;
  height: 50px;

  position: relative;
  top: 0;
  left: 0;
}

.title_box div {
  position: absolute;
  top: 0;
  width: 150px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  background-size: 100% 200%;
}

.title_box .title_left {
  left: 0;
  background-position: right center;
}

.title_box .title_cen {
  left: 150px;
  background-position: right center;
}

.title_box .title_right {
  left: 300px;
  background-position: right center;
}

.line {
  min-height: 170px;
  height: 30vh;
}

</style>
