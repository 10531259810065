<template>
  <div class="warps">
    <!-- 渠道货量占比 -->
    <dv-charts :option="option" />
  </div>
</template>

<script>
export default {
  props: {
    channelArr: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      option: {
        series: [
          {
            type: 'pie',
            data: [
              { name: '监控系统', value: 92 },
              { name: '收费系统', value: 65 },
              { name: '通信系统', value: 42 },
              { name: '供配电系统', value: 44 },
              { name: '其它', value: 52 }
            ],
            radius: ['70%', '75%'],
            outsideLabel: {
              labelLineEndLength: 30,
              formatter: '{percent}%\n{name}',
              style: {
                fill: '#fff'
              }
            }
          }
        ],
        color: ['#00c0ff', '#3de7c9', '#fff', '#00c0ff', '#3de7c9', '#fff']
      }

    };
  },

  watch: {
    channelArr: {
      handler(val) {
        console.log(val);
         if (val.length === 0) { return }
          const arr = val.map(item => {
            return {
              ...item
            };
          });
          const obj = JSON.parse(JSON.stringify(this.option));
          obj.series[0].data = arr;
          this.option = obj;
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
.warps {
  height: 250px;
  width: 400px;
  color: rgb(255, 255, 255);
}
</style>
