<template>
  <div>
    <dv-capsule-chart :config="config" style="width:400px;height:200px" />
  </div>
</template>

<script>
export default {
  props: {
    customerArr: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      config: {
        data: [
          {
            name: '南阳',
            value: 167
          },
          {
            name: '周口',
            value: 123
          },
          {
            name: '漯河',
            value: 98
          },
          {
            name: '郑州',
            value: 75
          },
          {
            name: '西峡',
            value: 66
          }
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '件',
        showValue: true
      }
    };
  },

  watch: {
    customerArr: {
      handler(val) {
        if (val.length !== 0) {
          const obj = JSON.parse(JSON.stringify(this.config));
          obj.data = val;
          this.config = obj;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>

</style>
