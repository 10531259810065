<template>
    <dv-active-ring-chart :config="config" style="width:300px;height:250px" />
</template>

<script>
export default {
  name: 'BottomLeftChart2',

  props: {
    countryArr: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
     config: {
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 78
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          }
        ],
        lineWidth: 10,
        radius: '80%',
        activeRadius: '85%'
      }

    };
  },

  watch: {
    countryArr: {
      handler(val) {
        console.log(val);
         if (val.length === 0) {return}
          const obj = JSON.parse(JSON.stringify(this.config));
          obj.data = val;
          this.config = obj;
        // }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

